import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/HighlightOff';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { setCadFilter } from '../actions/clientes';

const FiltrarClientes = ({
  dispatch,
  cadFilter,
}) => {
  function ejecutarBusquda(e) {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue && inputValue.trim()) {
      dispatch(setCadFilter(inputValue));
    } else {
      limpiarBusquda();
    }
  }

  function limpiarBusquda() {
    dispatch(setCadFilter(''));
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={10} style={{ padding: '0', paddingBottom: '1rem' }}>
        <form onSubmit={ejecutarBusquda}>
          <Paper elevation={1}>
            <Grid container justify="space-between" wrap="nowrap">
              <InputBase autoFocus
                className="w100"
                style={{ paddingLeft: '1rem' }}
                onChange={ejecutarBusquda}
                value={cadFilter}
                placeholder="Nombre de un cliente" />
              <IconButton onClick={limpiarBusquda} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};
FiltrarClientes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cadFilter: PropTypes.string.isRequired,
};

export default connect()(FiltrarClientes);
