const respuestasArrayCreator = (documents) => {
  let lastLetter = null;
  const respuestas = [];
  let indiceRespuestas = null;
  documents.forEach((document) => {
    if (document.indice.letraIndice !== lastLetter) {
      indiceRespuestas = indiceRespuestas === null ? 0 : indiceRespuestas + 1;
      respuestas.push([]);
    }
    respuestas[indiceRespuestas].push(document);
    lastLetter = document.indice.letraIndice;
  });
  return respuestas;
};

export default respuestasArrayCreator;
