import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import PickerTipo from '../components/Papeleria/Monografias/PickerTipo';
import { fetchDocuments } from '../actions/documentos';

const SearchDocument = ({
  dispatch,
  tipoDocumento,
  changeType,
}) => {
  let inputValue;

  function ejecutarBusquda(e) {
    e.preventDefault();
    if (inputValue && inputValue.trim()) {
      dispatch(fetchDocuments(tipoDocumento, inputValue));
    }
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={10} style={{ padding: '1rem' }}>
        <form onSubmit={ejecutarBusquda}>
          <PickerTipo
            currentDocumentType={tipoDocumento}
            changeType={changeType}
            optionsTypes={['Monografias', 'Biografias', 'Relieves', 'Esquemas', 'Mapas', 'MiniMapas']}
          />
          <Paper elevation={1}>
            <Grid container justify="space-between" wrap="nowrap">
              <InputBase autoFocus className="w100" style={{ paddingLeft: '1rem' }} onChange={(e) => { inputValue = e.target.value; }} placeholder="Introduce algo para buscar" />
              <IconButton onClick={ejecutarBusquda} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};
SearchDocument.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tipoDocumento: PropTypes.string.isRequired,
  changeType: PropTypes.func.isRequired,
};

export default connect()(SearchDocument);
