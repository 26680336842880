import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Container from '../../components/common/Container';
import Alerta from '../../components/common/Alerta';
import FormConfirm from '../../components/common/FormConfirm';

const FormConfirmContainer = ({
  onSubmit,
  pago,
  HCUserLogged,
  cancelSubmit,
  onSubmitSuccess,
}) => (
  <Container>
    <Grid container justify="center">
      <Grid item xs={12}>
        { pago.data
          ? (
            <>
              { pago.data.statusPago === 'Abonado'
                && (
                  <>
                    <Typography component="p" style={{ color: '#51c754' }} variant="h6" paragraph>
                      {`Abono anterior ${pago.data.cantidadPago}`}
                    </Typography>
                  </>
                )
              }
              <FormConfirm
                onSubmit={onSubmit}
                initialValues={
                  Object.assign({}, pago.data, {
                    passUsuario: '',
                    HCUserLogged: HCUserLogged.data,
                  })
                }
                onSubmitSuccess={onSubmitSuccess}
                cancelSubmit={cancelSubmit}
              />
            </>
          )
          : <Alerta message="Selecciona un mes" type="info" />
        }
      </Grid>
    </Grid>
  </Container>
);

FormConfirmContainer.propTypes = {
  pago: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({
      idPago: PropTypes.number.isRequired,
      cantidadPago: PropTypes.number.isRequired,
      cantidadAPagar: PropTypes.number.isRequired,
      statusPago: PropTypes.string.isRequired,
      notasPago: PropTypes.string.isRequired,
    }),
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  pago,
  HCUserLogged,
}) => ({
  pago,
  HCUserLogged,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormConfirmContainer);
