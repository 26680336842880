import { connect } from 'react-redux';
import { fetchMeses, clearMes, selectMes } from '../../actions/meses';
import { fetchGanancias } from '../../actions/ganancias';
import Ganancias from '../../components/Internet/Ganancias';

const mapStateToProps = ({
  meses,
  ganancias,
  HCUserLogged,
}) => ({
  meses,
  ganancias,
  HCUserLogged,
  idSelectedMes: meses.selectedMes ? meses.selectedMes.idMes : 0,
});

const mapDispatchToProps = {
  fetchMeses,
  fetchGanancias,
  clearMes,
  selectMes,
};

const GananciasContainer = connect(mapStateToProps, mapDispatchToProps)(Ganancias);

export default GananciasContainer;
