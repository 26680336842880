import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { documentsByType, selectedDocumentType } from './documentsReducer';
import clientesReducer from './clientesReducer';
import clienteReducer from './clienteReducer';
import loggedUser from './loggedUser';
import pagos from './pagos';
import meses from './meses';
import ganancias from './ganancias';
import pagosPendientes from './pagosPendientes';
import pago from './pago';
import alert from './alert';

const rootReducer = combineReducers({
  documentsByType,
  selectedDocumentType,
  clientes: clientesReducer,
  selectedCliente: clienteReducer,
  HCUserLogged: loggedUser,
  form: formReducer,
  pagos,
  meses,
  ganancias,
  pagosPendientes,
  pago,
  alert,
});

export default rootReducer;
