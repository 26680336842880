import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Container from '../../components/common/Container';
import Alerta from '../../components/common/Alerta';
import FormPago from '../../components/Internet/Pagos/FormPago';
import { fetchUpdatePago as fetchUpdatePagoAction } from '../../actions/pago';
import { fetchClientes as fetchClientesAction } from '../../actions/clientes';
import DatosCliente from '../../components/Internet/Clientes/DatosCliente';

const FormPagoContainer = ({
  fetchUpdatePago,
  pago,
  selectedCliente,
  history,
  fetchClientes,
  HCUserLogged,
}) => {
  const confirmSubmit = (values) => {
    // setOpen(true);
    const r = window.confirm('Desear realizar pago?');
    if (r) {
      fetchUpdatePago(values);
    }
    return null;
  };
  return (
    <Container>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          { pago.data
            ? (
              <>
                { selectedCliente.data
                  && <DatosCliente selectedCliente={selectedCliente} />
                }
                { pago.data.statusPago === 'Abonado'
                  && (
                    <>
                      <Typography component="p" style={{ color: '#51c754' }} variant="h6" paragraph>
                        {`Abono anterior ${pago.data.cantidadPago}`}
                      </Typography>
                    </>
                  )
                }
                <FormPago
                  onSubmit={confirmSubmit}
                  initialValues={
                    Object.assign({}, pago.data, {
                      cantidadPago: pago.data.cantidadAPagar - pago.data.cantidadPago,
                      HCUserLogged: HCUserLogged.data,
                    })
                  }
                  onSubmitSuccess={() => {
                    fetchClientes('ultimos-pagos', HCUserLogged.data);
                    history.goBack();
                  }}
                  cancelSubmit={() => history.goBack()}
                />
              </>
            )
            : <Alerta message="Selecciona un mes" type="info" />
          }
        </Grid>
      </Grid>
    </Container>
  );
};

FormPagoContainer.propTypes = {
  pago: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({
      idPago: PropTypes.number.isRequired,
      cantidadPago: PropTypes.number.isRequired,
      cantidadAPagar: PropTypes.number.isRequired,
      statusPago: PropTypes.string.isRequired,
      notasPago: PropTypes.string.isRequired,
    }),
  }).isRequired,
  selectedCliente: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      telefonoCliente: PropTypes.string.isRequired,
      comunidadCliente: PropTypes.string.isRequired,
      direccionCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
      fechaRegistro: PropTypes.string.isRequired,
      fechaActualizacion: PropTypes.string.isRequired,
      fk_idAntena: PropTypes.number.isRequired,
    }),
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  fetchUpdatePago: PropTypes.func.isRequired,
  fetchClientes: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = ({
  pago,
  selectedCliente,
  HCUserLogged,
}) => ({
  pago,
  selectedCliente,
  HCUserLogged,
});

const mapDispatchToProps = {
  fetchUpdatePago: fetchUpdatePagoAction,
  fetchClientes: fetchClientesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPagoContainer);
