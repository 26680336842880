import {
  SET_FILTER,
  REQUEST_CLIENTES,
  RECEIVE_CLIENTES,
  RECEIVE_CLIENTES_FAIL,
} from '../actions/clientes';

const clientesReducer = (state = {
  isFetching: false,
  isError: false,
  messageError: '',
  cadFilter: '',
  data: [],
}, action) => {
  switch (action.type) {
    case SET_FILTER:
      return Object.assign({}, state, {
        cadFilter: action.cadFilter,
      });
    case REQUEST_CLIENTES:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        messageError: '',
        cadFilter: '',
      });
    case RECEIVE_CLIENTES:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.documents,
      });
    case RECEIVE_CLIENTES_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        messageError: action.documents,
        cadFilter: '',
        data: [],
      });
    default:
      return state;
  }
};

export default clientesReducer;
