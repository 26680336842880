import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { clearLoggedUser } from '../loggedUser';

export const SELECT_PAGO = 'SELECT_PAGO';
export const REQUEST_UPDATE_PAGO = 'REQUEST_UPDATE_PAGO';
export const RECEIVE_UPDATED_PAGO = 'RECEIVE_UPDATED_PAGO';
export const RECEIVE_UPDATED_PAGO_FAIL = 'RECEIVE_UPDATED_PAGO_FAIL';
export const CLEAR_PAGO = 'CLEAR_PAGO';

export const selectPago = pago => ({
  type: SELECT_PAGO,
  pago,
});

export const clearPago = () => ({
  type: CLEAR_PAGO,
});

const requestUpdatePago = () => ({
  type: REQUEST_UPDATE_PAGO,
});

const receiveUpdatedPago = json => ({
  type: RECEIVE_UPDATED_PAGO,
  pagos: json.data.pago,
});

const getUpdatedPagoError = json => ({
  type: RECEIVE_UPDATED_PAGO_FAIL,
  messageError: json.error,
});

export const fetchUpdatePago = pago => (dispatch) => {
  dispatch(requestUpdatePago());
  return fetch(`${GLOBAL.url}pagos/${pago.idPago}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        api_token: pago.HCUserLogged.api_token,
        idUsuario: pago.HCUserLogged.idUsuario,
      },
      body: JSON.stringify(pago),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveUpdatedPago(json));
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(getUpdatedPagoError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser());
          dispatch(showAlert('warning', 'Tu sesión expiro'));
        } else {
          dispatch(showAlert('warning', json.error));
        }
      }
    })
    .catch(() => {
      dispatch(getUpdatedPagoError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};

export const fetchResetPago = pago => (dispatch) => {
  dispatch(requestUpdatePago());
  return fetch(`${GLOBAL.url}pagos/reset/${pago.idPago}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        api_token: pago.HCUserLogged.api_token,
        idUsuario: pago.HCUserLogged.idUsuario,
        passUsuario: pago.passUsuario,
      },
      body: JSON.stringify(pago),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveUpdatedPago(json));
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(getUpdatedPagoError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser());
          dispatch(showAlert('warning', 'Tu sesión expiro'));
        } else {
          dispatch(showAlert('warning', json.error));
        }
      }
    })
    .catch(() => {
      dispatch(getUpdatedPagoError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};

export const fetchDeshabilitarPago = pago => (dispatch) => {
  dispatch(requestUpdatePago());
  return fetch(`${GLOBAL.url}pagos/deshabilitar/${pago.idPago}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        api_token: pago.HCUserLogged.api_token,
        idUsuario: pago.HCUserLogged.idUsuario,
        passUsuario: pago.passUsuario,
      },
      body: JSON.stringify(pago),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveUpdatedPago(json));
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(getUpdatedPagoError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser());
          dispatch(showAlert('warning', 'Tu sesión expiro'));
        } else {
          dispatch(showAlert('warning', json.error));
        }
      }
    })
    .catch(() => {
      dispatch(getUpdatedPagoError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};
