import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderSection from '../common/HeaderSection';

const menuItemsInternetAdmin = [
  {
    name: 'Pagos',
    url: '/internet/pagos',
  },
  {
    name: 'Clientes',
    url: '/internet/clientes',
  },
  {
    name: 'Ganancias',
    url: '/internet/ganancias',
  },
];

const menuItemsInternet = [
  {
    name: 'Pagos',
    url: '/internet/pagos',
  },
  {
    name: 'Clientes',
    url: '/internet/clientes',
  },
];

const Internet = ({
  fetchClientes,
  HCUserLogged,
}) => {
  useEffect(() => {
    fetchClientes('ultimos-pagos', HCUserLogged.data);
  }, []);
  return (
    <React.Fragment>
      { HCUserLogged.data.rolUsuario === 'Admin'
      ? <HeaderSection linksList={menuItemsInternetAdmin} />
      : <HeaderSection linksList={menuItemsInternet} />
      }
    </React.Fragment>
  );
};

Internet.propTypes = {
  fetchClientes: PropTypes.func.isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
};
export default Internet;
