import {
  REQUEST_PAGOS_PENDIENTES,
  RECEIVE_PAGOS_PENDIENTES,
  RECEIVE_PAGOS_PENDIENTES_FAIL,
  CLEAR_PAGOS_PENDIENTES,
} from '../actions/pagosPendientes';

const pagos = (state = {
  isFetching: false,
  messageError: '',
  data: [],
}, action) => {
  switch (action.type) {
    case RECEIVE_PAGOS_PENDIENTES:
      return {
        isFetching: false,
        messageError: '',
        data: action.pagos,
      };
    case CLEAR_PAGOS_PENDIENTES:
      return Object.assign({}, state, {
        messageError: '',
        data: [],
      });
    case REQUEST_PAGOS_PENDIENTES:
      return {
        isFetching: true,
        messageError: '',
        data: [],
      };
    case RECEIVE_PAGOS_PENDIENTES_FAIL:
      return {
        isFetching: false,
        messageError: action.messageError,
        data: [],
      };
    default:
      return state;
  }
};

export default pagos;
