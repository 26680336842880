import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/PersonAdd';
import green from '@material-ui/core/colors/green';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Container from '../../common/Container';
import Loading from '../../common/Loading';
import ClientesList from './ClientesList';
import FloatButton from '../../common/FloatButton';
import ClienteVisor from './ClienteVisor';

const Clientes = ({
  clientes,
  selectCliente,
  selectedCliente,
  clearCliente,
  toggleCliente,
  HCUserLogged,
}) => {
  useEffect(() => {
    clearCliente();
  }, []);
  return (
    <Container>
      <Grid container spacing={8}>
        {clientes.isFetching
        && (
          <Grid item xs={12}>
            <Loading />
          </Grid>
        )}
        <Hidden smDown={(selectedCliente.data && true)}>
          <Grid item xs={12} md={3}>
            { clientes.data
              && (
                <ClientesList
                  selectCliente={selectCliente}
                  clientes={clientes.data}
                  clearCliente={clearCliente}
                />
              )
            }
          </Grid>
        </Hidden>
        <Grid item xs={12} md={9}>
          { selectedCliente.data
            ? (
              <ClienteVisor
                toggleCliente={toggleCliente}
                clearCliente={clearCliente}
                selectedCliente={selectedCliente.data}
                HCUserLogged={HCUserLogged}
              />
            )
            : !clientes.isFetching
              && <Typography align="center" variant="h5" component="h2">Selecciona un cliente</Typography>
          }
        </Grid>
      </Grid>
      <FloatButton component={Link} to="/internet/clientes/new" icon={<AddIcon />} color={green[500]} />
    </Container>
  );
};

Clientes.propTypes = {
  toggleCliente: PropTypes.func.isRequired,
  clientes: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  selectCliente: PropTypes.func.isRequired,
  clearCliente: PropTypes.func.isRequired,
  selectedCliente: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    }),
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
};

export default Clientes;
