import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { selectDocumentType, fetchDocuments } from '../actions/documentos';
import Monografias from '../components/Papeleria/Monografias';
import SearchDocument from './SearchDocument';
import Loading from '../components/common/Loading';
import Container from '../components/common/Container';

class AsyncMonografias extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, selectedDocumentType } = this.props;
    dispatch(fetchDocuments(selectedDocumentType));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, selectedDocumentType } = this.props;
    if (selectedDocumentType !== prevProps.selectedDocumentType) {
      dispatch(fetchDocuments(selectedDocumentType));
    }
  }

  handleChange(nextDocumentType) {
    const { dispatch } = this.props;
    dispatch(selectDocumentType(nextDocumentType));
    dispatch(fetchDocuments(nextDocumentType));
  }

  render() {
    const {
      selectedDocumentType,
      documents,
      isFetching,
      isError,
      messageError,
    } = this.props;
    return (
      <Container style={{ opacity: isFetching ? 0.5 : 1 }}>
        <SearchDocument tipoDocumento={selectedDocumentType} changeType={this.handleChange} />
        <h2>{`Buscando en ${selectedDocumentType}`}</h2>
        {isFetching
          && <Loading />
        }
        {!isFetching && documents.length === 0
          && <h4>Sin resultados.</h4>
        }
        {isError
          && <SnackbarContent message={`Un error ha ocurrido: '${messageError}`} />
        }
        {(documents.length > 0 && !isFetching)
          && (
            <Monografias
              selectedDocumentType={selectedDocumentType}
              documents={documents}
              isError={isError}
              messageError={messageError}
            />
          )
        }
      </Container>
    );
  }
}

AsyncMonografias.propTypes = {
  selectedDocumentType: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      nombreDocumento: PropTypes.string.isRequired,
      idDocumento: PropTypes.number.isRequired,
      posicionDocumento: PropTypes.string.isRequired,
      tipoDocumento: PropTypes.string.isRequired,
      indice: PropTypes.shape({
        idIndice: PropTypes.number.isRequired,
        letraIndice: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  messageError: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { selectedDocumentType, documentsByType } = state;
  const {
    isFetching,
    isError,
    messageError,
    items: documents,
  } = documentsByType[selectedDocumentType] || {
    isFetching: true,
    isError: false,
    messageError: '',
    items: [],
  };
  return {
    selectedDocumentType,
    documents,
    isFetching,
    isError,
    messageError,
  };
}

export default connect(mapStateToProps)(AsyncMonografias);
