import {
  SET_ALERT,
  TOGGLE_ALERT,
} from '../actions/alert';

const alert = (state = {
  isOpen: false,
  message: 'Sin mensaje',
  type: 'success',
}, action) => {
  switch (action.type) {
    case SET_ALERT:
      return action.alert;
    case TOGGLE_ALERT:
      return Object.assign({}, state, {
        isOpen: action.isOpen,
      });
    default:
      return state;
  }
};

export default alert;
