import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { clearLoggedUser } from '../loggedUser';
import { showAlert } from '../alert';

export const REQUEST_PAGOS = 'REQUEST_PAGOS';
export const RECEIVE_PAGOS = 'RECEIVE_PAGOS';
export const RECEIVE_PAGOS_FAIL = 'RECEIVE_PAGOS_FAIL';
export const CLEAR_PAGOS = 'CLEAR_PAGOS';

export const clearPagos = () => ({
  type: CLEAR_PAGOS,
});

const requestPagos = () => ({
  type: REQUEST_PAGOS,
});

const receivePagos = json => ({
  type: RECEIVE_PAGOS,
  pagos: json.data.pagos,
});

const getPagosError = json => ({
  type: RECEIVE_PAGOS_FAIL,
  messageError: json.error,
});

export const fetchPagos = (idCliente, HCUserLogged) => (dispatch) => {
  dispatch(requestPagos());
  return fetch(`${GLOBAL.url}clientes/${idCliente}/historial`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receivePagos(json));
      } else {
        dispatch(getPagosError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(showAlert('warning', 'Tu sesión expiro'));
          dispatch(clearLoggedUser());
        }
      }
    })
    .catch(() => {
      dispatch(getPagosError({ error: 'Verifica tu conexion a internet' }));
    });
};
