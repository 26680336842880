import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ClienteIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const ClientesList = ({
  clientes,
  selectCliente,
}) => (
  <List style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
    { clientes.map(cliente => (
      <ListItem onClick={() => selectCliente(cliente)} key={cliente.idCliente} className="cursor-pointer" alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp">
            <ClienteIcon style={{ fontSize: '2rem' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={cliente.nombreCliente}
          secondary={(
            <React.Fragment>
              <Typography component="span" color="textPrimary">
                {cliente.comunidadCliente}
              </Typography>
              {cliente.direccionCliente}
            </React.Fragment>
          )}
        />
      </ListItem>
    ))}
  </List>
);

ClientesList.propTypes = {
  selectCliente: PropTypes.func.isRequired,
  clientes: PropTypes.arrayOf(PropTypes.shape({
    idCliente: PropTypes.number.isRequired,
    folioCliente: PropTypes.string.isRequired,
    nombreCliente: PropTypes.string.isRequired,
    mensualidadCliente: PropTypes.number.isRequired,
    statusCliente: PropTypes.string.isRequired,
  })).isRequired,
};

export default ClientesList;
