import {
    REQUEST_MESES,
    RECEIVE_MESES,
    RECEIVE_MESES_FAIL,
    CLEAR_MES,
    SELECT_MES
  } from '../actions/meses';
  
  const meses = (state = {
    isFetching: false,
    messageError: '',
    data: [],
    selectedMes: null,
  }, action) => {
    switch (action.type) {
      case SELECT_MES:
        return Object.assign({}, state, {
          selectedMes: action.mes,
        });
      case RECEIVE_MESES:
        return Object.assign({}, state, {
          isFetching: false,
          data: action.meses,
        });
      case CLEAR_MES:
        return Object.assign({}, state, {
          selectedMes: null,
        });
      case REQUEST_MESES:
        return Object.assign({}, state, {
          isFetching: true,
          messageError: '',
          data: [],
        });
      case RECEIVE_MESES_FAIL:
        return Object.assign({}, state, {
          isFetching: false,
          messageError: action.messageError,
        });
      default:
        return state;
    }
  };
  
  export default meses;
  