import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class LoadError extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children, classes } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3">
            Error al cargar seccion.
          </Typography>
          <Typography component="p">
            Verifica tu coneccion de red e intentalo de nuevo.
          </Typography>
        </Paper>
      );
    }
    return children;
  }
}

LoadError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(LoadError);
