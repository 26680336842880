import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_USUARIO = 'RECEIVE_USUARIO';
export const RECEIVE_USUARIO_FAIL = 'RECEIVE_USUARIO_FAIL';
export const LOGIN_USUARIO = 'LOGIN_USUARIO';
export const LOGOUT_USUARIO = 'LOGOUT_USUARIO';

export const setLoggedUser = usuario => ({
  type: LOGIN_USUARIO,
  usuario,
});

export const clearLoggedUser = () => ({
  type: LOGOUT_USUARIO,
});

const requestLogin = () => ({
  type: REQUEST_LOGIN,
});

const receiveUser = json => ({
  type: RECEIVE_USUARIO,
  usuario: json.data,
});

const getUsuarioError = json => ({
  type: RECEIVE_USUARIO_FAIL,
  messageError: json.error,
});

export const fetchLogin = datosLogin => (dispatch) => {
  dispatch(requestLogin());
  return fetch(`${GLOBAL.url}usuarios/login`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(datosLogin),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveUser(json));
      } else {
        dispatch(getUsuarioError(json));
      }
    })
    .catch(() => {
      dispatch(getUsuarioError({ error: 'Verifica tu conexion a internet' }));
    });
};
