const buscarMes = (meses, idMes) => {
    let nuevoMes = null;
    meses.forEach((mes) => {
      if (mes.idMes === idMes) {
        nuevoMes = mes;
      }
    });
    return nuevoMes;
  };
  
  export default buscarMes;
  