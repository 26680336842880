import React from 'react';
import { Redirect } from 'react-router-dom';

const IsLogged = (Component, HCUserLogged) => (
  HCUserLogged.isLogged
    ? <Redirect to="/internet/pagos" />
    : Component
);

export default IsLogged;
