import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { clearLoggedUser } from '../loggedUser';

export const REQUEST_GANANCIAS = 'REQUEST_GANANCIAS';
export const RECEIVE_GANANCIAS = 'RECEIVE_GANANCIAS';
export const RECEIVE_GANANCIAS_FAIL = 'RECEIVE_GANANCIAS_FAIL';
export const CLEAR_GANANCIAS = 'CLEAR_GANANCIAS';

export const clearGanancias = () => ({
  type: CLEAR_GANANCIAS,
});

const requestGanancias = () => ({
  type: REQUEST_GANANCIAS,
});

const receiveGanancias = json => ({
  type: RECEIVE_GANANCIAS,
  ganancias: json.data,
});

const getGananciasError = json => ({
  type: RECEIVE_GANANCIAS_FAIL,
  ganancias: json.error,
});

export const fetchGanancias = (HCUserLogged, idMes) => (dispatch) => {
  dispatch(requestGanancias());
  return fetch(
    `${GLOBAL.url}ganancias/mes/${idMes}`,
    {
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    },
  )
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveGanancias(json));
      } else {
        dispatch(getGananciasError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser());
          dispatch(showAlert('warning', 'Tu sesión expiro'));
        }
      }
    })
    .catch(() => {
      dispatch(getGananciasError({ error: 'Verifica tu conexion a internet' }));
    });
};