import React from 'react';
import HeaderSection from '../common/HeaderSection';

const menuItemsPapeleria = [
  {
    name: 'Monografias',
    url: '/papeleria/monografias',
  },
  {
    name: 'Faltantes',
    url: '/papeleria/faltantes',
  },
  {
    name: 'Deudores',
    url: '/papeleria/deudores',
  },
];

const Papeleria = () => (
  <React.Fragment>
    <HeaderSection linksList={menuItemsPapeleria} />
  </React.Fragment>
);

export default Papeleria;
