import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputField from '../Form/InputField';
import Loading from '../Loading';

const validate = (values) => {
  const errors = {};
  if (!values.passUsuario) {
    errors.passUsuario = 'Debes introducir contraeña';
  } else if (values.passUsuario.length < 8 || values.passUsuario.length > 17) {
    errors.passUsuario = 'Longitud de contraseña no valida';
  }
  return errors;
};

const FormConfirm = ({
  handleSubmit,
  cancelSubmit,
  submitting,
}) => (
  <Grid>
    { submitting ? <Loading />
      : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Field
                name="passUsuario"
                component={InputField}
                label="Contraseña"
                type="password"
                fullWidth
              />
            </Grid>
            <Grid item container justify="space-around">
              <Button variant="contained" className="m-left-1" onClick={cancelSubmit} color="secondary">
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    }
  </Grid>
);

FormConfirm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'pagoForm',
  validate,
})(FormConfirm);
