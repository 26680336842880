export const SET_ALERT = 'SET_ALERT';
export const TOGGLE_ALERT = 'TOGGLE_ALERT';

export const setAlert = alert => ({
  type: SET_ALERT,
  alert,
});

export const showAlert = (type, message) => ({
  type: SET_ALERT,
  alert: {
    isOpen: true,
    type,
    message,
  },
});
export const toggleAlert = isOpen => ({
  type: TOGGLE_ALERT,
  isOpen,
});
