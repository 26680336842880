import {
  REQUEST_UPDATE_PAGO,
  RECEIVE_UPDATED_PAGO,
  RECEIVE_UPDATED_PAGO_FAIL,
  SELECT_PAGO,
  CLEAR_PAGO,
} from '../actions/pago';

const pago = (state = {
  isFetching: false,
  messageError: null,
  data: null,
}, action) => {
  switch (action.type) {
    case SELECT_PAGO:
      return Object.assign({}, state, {
        isFetching: false,
        messageError: null,
        data: action.pago,
      });
    case RECEIVE_UPDATED_PAGO:
      return Object.assign({}, state, {
        isFetching: false,
        messageError: null,
        data: action.pago,
      });
    case CLEAR_PAGO:
      return Object.assign({}, state, {
        messageError: null,
        data: null,
      });
    case REQUEST_UPDATE_PAGO:
      return Object.assign({}, state, {
        isFetching: true,
        messageError: null,
      });
    case RECEIVE_UPDATED_PAGO_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        messageError: action.messageError,
        data: null,
      });
    default:
      return state;
  }
};

export default pago;
