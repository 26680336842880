import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { fetchClientes } from '../clientes';
import { clearLoggedUser } from '../loggedUser/index';

export const REQUEST_CLIENTE = 'REQUEST_CLIENTE';
export const CREATE_CLIENTE = 'CREATE_CLIENTE';
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
export const RECEIVE_CLIENTE = 'RECEIVE_CLIENTE';
export const RECEIVE_CLIENTE_FAIL = 'RECEIVE_CLIENTE_FAIL';
export const SELECT_CLIENTE = 'SELECT_CLIENTE';
export const CLEAR_CLIENTE = 'CLEAR_CLIENTE';

export const selectCliente = cliente => ({
  type: SELECT_CLIENTE,
  cliente,
});

export const clearCliente = () => ({
  type: CLEAR_CLIENTE,
});

const requestCliente = () => ({
  type: REQUEST_CLIENTE,
});

const receiveCliente = json => ({
  type: RECEIVE_CLIENTE,
  documents: json.data,
});

const getClienteError = json => ({
  type: RECEIVE_CLIENTE_FAIL,
  documents: json.error,
});

export const fetchCliente = (url = '', HCUserLogged) => (dispatch) => {
  dispatch(requestCliente());
  return fetch(
    `${GLOBAL.url}clientes/${url}`,
    {
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    },
  )
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveCliente(json));
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(getClienteError(json.error));
        dispatch(showAlert('error', json.error));
      }
    })
    .catch(() => {
      dispatch(getClienteError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};

export const createCliente = dataCliente => (dispatch) => {
  dispatch(requestCliente());
  return fetch(`${GLOBAL.url}clientes`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        api_token: dataCliente.HCUserLogged.api_token,
        idUsuario: dataCliente.HCUserLogged.idUsuario,
      },
      body: JSON.stringify(dataCliente),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveCliente(json));
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(getClienteError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser);
          dispatch(showAlert('warning', json.error));
        } else {
          dispatch(showAlert('error', json.error));
        }
      }
    })
    .catch(() => {
      dispatch(getClienteError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};

export const updateCliente = dataCliente => (dispatch) => {
  dispatch(requestCliente());
  return fetch(`${GLOBAL.url}clientes/${dataCliente.idCliente}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        api_token: dataCliente.HCUserLogged.api_token,
        idUsuario: dataCliente.HCUserLogged.idUsuario,
      },
      body: JSON.stringify(dataCliente),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveCliente(json));
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(getClienteError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser);
          dispatch(showAlert('warning', json.error));
        } else {
          dispatch(showAlert('error', json.error));
        }
      }
    })
    .catch(() => {
      dispatch(getClienteError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};

export const toggleCliente = (idCliente, HCUserLogged) => (dispatch) => {
  dispatch(requestCliente());
  return fetch(`${GLOBAL.url}clientes/toggle/${idCliente}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveCliente(json));
        dispatch(showAlert('success', json.message));
        dispatch(fetchClientes('ultimos-pagos', HCUserLogged));
      } else {
        dispatch(getClienteError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser);
          dispatch(showAlert('warning', 'Tu sesion ha expirado'));
        } else {
          dispatch(showAlert('error', json.error));
        }
      }
    })
    .catch(() => {
      dispatch(getClienteError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};
