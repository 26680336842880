import fetch from 'isomorphic-fetch';
import GLOBAL from './config';

export const REQUEST_DOCUMENTS = 'REQUEST_DOCUMENTS';
export const RECEIVE_DOCUMENTS = 'RECEIVE_DOCUMENTS';
export const RECEIVE_DOCUMENTS_FAIL = 'RECEIVE_DOCUMENTS_FAIL';
export const SELECT_TIPO_DOCUMENTO = 'SELECT_TIPO_DOCUMENTO';

export const selectDocumentType = tipoDocumento => ({
  type: SELECT_TIPO_DOCUMENTO,
  tipoDocumento,
});

const requestDocuments = tipoDocumento => ({
  type: REQUEST_DOCUMENTS,
  tipoDocumento,
});

const receiveDocuments = (tipoDocumento, json) => ({
  type: RECEIVE_DOCUMENTS,
  tipoDocumento,
  documents: json.data,
});

const getDocumentsError = (tipoDocumento, json) => ({
  type: RECEIVE_DOCUMENTS_FAIL,
  tipoDocumento,
  documents: json.error,
});

export const fetchDocuments = (tipoDocumento, cadenaNombre = '1') => (dispatch) => {
  dispatch(requestDocuments(tipoDocumento));
  return fetch(`${GLOBAL.url}documentos/${tipoDocumento}/${cadenaNombre}`, { headers: { 'Content-Type': 'application/json', api_token: 'abcdefghijklmnopqrstuvwxyz', idUsuario: 1 } })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveDocuments(tipoDocumento, json));
      } else {
        dispatch(getDocumentsError(tipoDocumento, json));
      }
    })
    .catch(() => {
      dispatch(getDocumentsError(tipoDocumento, { error: 'Verifica tu conexion a internet' }));
    });
};
