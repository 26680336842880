import { connect } from 'react-redux';
import { selectCliente, clearCliente } from '../../actions/cliente';
import { selectPago, clearPago } from '../../actions/pago';
import { fetchPagosPendientes, clearPagosPendientes } from '../../actions/pagosPendientes';
import NuevoPago from '../../components/Internet/Pagos/NuevoPago';

const mapStateToProps = ({
  clientes,
  selectedCliente,
  pago,
  pagosPendientes,
  HCUserLogged,
}) => ({
  clientes,
  idClienteSelected: selectedCliente.data ? selectedCliente.data.idCliente : 0,
  idMesSelected: pago.data ? pago.data.idPago : 0,
  pagosPendientes,
  HCUserLogged,
});

const mapDispatchToProps = {
  selectCliente,
  clearCliente,
  selectPago,
  clearPago,
  fetchPagosPendientes,
  clearPagosPendientes,
};

const NuevoPagoContainer = connect(mapStateToProps, mapDispatchToProps)(NuevoPago);

export default NuevoPagoContainer;
