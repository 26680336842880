import React, { Suspense } from 'react';
import LoadError from '../../common/LoadError';
import Loading from '../../common/Loading';

const Faltantes = () => (
  <div>
    <h1>
      Faltantes Component
    </h1>
    <LoadError>
      <Suspense fallback={<Loading />}>
        puro pensar
      </Suspense>
    </LoadError>
  </div>
);

export default Faltantes;
