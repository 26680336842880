import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { clearLoggedUser } from '../loggedUser';

export const SET_FILTER = 'SET_FILTER';
export const REQUEST_CLIENTES = 'REQUEST_CLIENTES';
export const RECEIVE_CLIENTES = 'RECEIVE_CLIENTES';
export const RECEIVE_CLIENTES_FAIL = 'RECEIVE_CLIENTES_FAIL';

const requestClientes = cadFilter => ({
  type: REQUEST_CLIENTES,
  cadFilter,
});

const receiveClientes = json => ({
  type: RECEIVE_CLIENTES,
  documents: json.data,
});

const getClientesError = json => ({
  type: RECEIVE_CLIENTES_FAIL,
  documents: json.error,
});

export const setCadFilter = cadFilter => ({
  type: SET_FILTER,
  cadFilter,
});

export const fetchClientes = (url = '', HCUserLogged) => (dispatch) => {
  dispatch(requestClientes());
  return fetch(
    `${GLOBAL.url}clientes/${url}`,
    {
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    },
  )
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveClientes(json));
      } else {
        dispatch(getClientesError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser());
          dispatch(showAlert('warning', 'Tu sesión expiro'));
        }
      }
    })
    .catch(() => {
      dispatch(getClientesError({ error: 'Verifica tu conexion a internet' }));
    });
};
