import {
  REQUEST_CLIENTE,
  RECEIVE_CLIENTE,
  RECEIVE_CLIENTE_FAIL,
  SELECT_CLIENTE,
  CLEAR_CLIENTE,
} from '../actions/cliente';

const clienteReducer = (state = {
  isFetching: false,
  data: null,
}, action) => {
  switch (action.type) {
    case SELECT_CLIENTE:
      return Object.assign({}, state, {
        data: action.cliente,
      });
    case CLEAR_CLIENTE:
      return Object.assign({}, state, {
        data: null,
      });
    case REQUEST_CLIENTE:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_CLIENTE:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      });
    case RECEIVE_CLIENTE_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        data: null,
      });
    default:
      return state;
  }
};

export default clienteReducer;
