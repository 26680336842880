import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { clearLoggedUser } from '../loggedUser';

export const REQUEST_PAGOS_PENDIENTES = 'REQUEST_PAGOS_PENDIENTES';
export const RECEIVE_PAGOS_PENDIENTES = 'RECEIVE_PAGOS_PENDIENTES';
export const RECEIVE_PAGOS_PENDIENTES_FAIL = 'RECEIVE_PAGOS_PENDIENTES_FAIL';
export const CLEAR_PAGOS_PENDIENTES = 'CLEAR_PAGOS_PENDIENTES';

const requestPagosPendientes = () => ({
  type: REQUEST_PAGOS_PENDIENTES,
});

const receivePagosPendientes = json => ({
  type: RECEIVE_PAGOS_PENDIENTES,
  pagos: json.data,
});

const getPagosPendientesError = json => ({
  type: RECEIVE_PAGOS_PENDIENTES_FAIL,
  messageError: json.error,
});

export const clearPagosPendientes = () => ({
  type: CLEAR_PAGOS_PENDIENTES,
});

export const fetchPagosPendientes = (idCliente, HCUserLogged) => (dispatch) => {
  dispatch(requestPagosPendientes());
  return fetch(`${GLOBAL.url}pagos/pendientes/cliente/${idCliente}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receivePagosPendientes(json));
        // dispatch(showAlert('success', json.message));
      } else {
        dispatch(getPagosPendientesError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(showAlert('warning', 'Tu sesión ha expirado'));
          dispatch(clearLoggedUser());
        }
      }
    })
    .catch(() => {
      dispatch(getPagosPendientesError({ error: 'Verifica tu conexion a internet' }));
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};
