import { connect } from 'react-redux';
import { fetchLogin } from '../actions/loggedUser';
import Login from '../components/login/index';

const mapStateToProps = ({
  HCUserLogged,
}) => ({
  HCUserLogged,
});

const mapDispatchToProps = {
  onSubmit: fetchLogin,
};

const ClientesContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default ClientesContainer;
