import {
  REQUEST_LOGIN,
  RECEIVE_USUARIO,
  RECEIVE_USUARIO_FAIL,
  LOGIN_USUARIO,
  LOGOUT_USUARIO,
} from '../actions/loggedUser';

const clienteReducer = (state = {
  isFetching: false,
  isLogged: false,
  messageError: null,
  data: null,
}, action) => {
  switch (action.type) {
    case LOGIN_USUARIO:
      return Object.assign({}, state, {
        isLogged: true,
        messageError: null,
        data: action.usuario,
      });
    case LOGOUT_USUARIO:
      return Object.assign({}, state, {
        isLogged: false,
        data: null,
      });
    case REQUEST_LOGIN:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_USUARIO:
      return Object.assign({}, state, {
        isFetching: false,
        isLogged: true,
        messageError: null,
        data: action.usuario,
      });
    case RECEIVE_USUARIO_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        isLogged: false,
        messageError: action.messageError,
        data: null,
      });
    default:
      return state;
  }
};

export default clienteReducer;
