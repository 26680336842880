const buscarPago = (pagos, idPago) => {
  let nuevoPago = null;
  pagos.forEach((pago) => {
    if (pago.idPago === idPago) {
      nuevoPago = pago;
    }
  });
  return nuevoPago;
};

export default buscarPago;
