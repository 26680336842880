import React from 'react';
import PropTypes from 'prop-types';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';

const FloatButton = ({
  icon,
  color,
  classes,
  to,
  component,
  variant,
  textButton,
}) => (
  <Zoom
    in
    timeout={{
      enter: 300,
      exit: 300,
    }}
    unmountOnExit
  >
    { (to && component)
      ? (
        <Fab component={component} to={to} size="large" variant={variant} className={classes.fab} style={{ backgroundColor: color }}>
          {icon}
          {textButton}
        </Fab>
      )
      : (
        <Fab size="large" className={classes.fab} style={{ backgroundColor: color }}>
          {icon}
        </Fab>
      )
    }
  </Zoom>
);

FloatButton.propTypes = {
  icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  to: PropTypes.string,
  component: PropTypes.func,
  variant: PropTypes.string,
  textButton: PropTypes.string,
};

FloatButton.defaultProps = {
  to: null,
  component: null,
  variant: 'round',
  textButton: null,
};

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
    color: theme.palette.common.white,
    '&:hover': {
      opacity: '.9',
    },
  },
});

export default withStyles(styles, { withTheme: true })(FloatButton);
