import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import '../../../../node_modules/react-vis/dist/style.css';
import { RadialChart } from 'react-vis';


const Estadisticas = ({
  ganancias,
}) => (
  <Grid container>
    <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <RadialChart
        innerRadius={100}
        radius={150}
        align={'center'}
        showLabels
        data={[
          { angle: parseInt(ganancias.data.cantidadPago), labelColor: "#00FF00", label: ganancias.data.cantidadPago, subLabel: 'Recibido'},
          { angle: parseInt(ganancias.data.restante), color: "#FF0000", label: ganancias.data.restante, subLabel: 'Faltante'},
          { },
          { },
          { },
        ]}
        width={400}
        height={400} />
      <Typography variant="h5" component="h3">
        Ganancia total: ${ganancias.data.cantidadAPagar}
      </Typography>
    </Grid>
  </Grid>
);

Estadisticas.propTypes = {
  ganancias: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.shape({
      idMes: PropTypes.number.isRequired,
      nombreMes: PropTypes.string.isRequired,
      cantidadAPagar: PropTypes.string.isRequired,
      cantidadPago: PropTypes.string.isRequired,
      restante: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Estadisticas;
