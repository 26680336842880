import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const Container = ({
  children,
}) => (
  <Grid container justify="center" style={{ padding: '1rem' }}>
    <Grid item xs={12} md={11}>
      {children}
    </Grid>
  </Grid>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
