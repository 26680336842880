import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '../common/Container';
import InputField from '../common/Form/InputField';
import Loading from '../common/Loading';

const Login = ({
  handleSubmit,
  submitting,
}) => (
  <Container>
    <Grid container justify="center">
      { submitting ? <Loading />
        : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Field
                  name="nombreUsuario"
                  component={InputField}
                  label="Nombre de Usuario"
                  placeholder="Ej. admin1"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="passUsuario"
                  component={InputField}
                  label="Contraseña"
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item container justify="center">
                <Button type="submit" variant="contained" color="primary">
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }
    </Grid>
  </Container>
);

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'loginForm',
})(Login);
