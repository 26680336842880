import {
    REQUEST_GANANCIAS,
    RECEIVE_GANANCIAS,
    RECEIVE_GANANCIAS_FAIL,
    CLEAR_GANANCIAS,
  } from '../actions/ganancias';
  
  const ganancias = (state = {
    isFetching: false,
    messageError: '',
    data: null,
  }, action) => {
    switch (action.type) {
      case RECEIVE_GANANCIAS:
        return Object.assign({}, state, {
          isFetching: false,
          data: action.ganancias,
        });
      case CLEAR_GANANCIAS:
        return Object.assign({}, state, {
        });
      case REQUEST_GANANCIAS:
        return Object.assign({}, state, {
          isFetching: true,
          messageError: '',
          data: null,
        });
      case RECEIVE_GANANCIAS_FAIL:
        return Object.assign({}, state, {
          isFetching: false,
          messageError: action.messageError,
        });
      default:
        return state;
    }
  };
  
  export default ganancias;