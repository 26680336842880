import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  // Badge,
  Menu,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import FaltantesIcon from '@material-ui/icons/PlaylistAddCheck';
import CashIcon from '@material-ui/icons/MonetizationOn';
import ClientesIcon from '@material-ui/icons/PeopleOutline';
import GananciasIcon from '@material-ui/icons/TrendingUpRounded';
import MonografiasIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    textDecoration: 'none',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class Layout extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const {
      classes,
      children,
      HCUserLogged,
      clearLoggedUser,
    } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        onClick={this.handleMenuClose}
      >
        <MenuItem component={Link} to="/perfil">Ajustes</MenuItem>
        <MenuItem onClick={clearLoggedUser}>Salir</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {
          HCUserLogged.isLogged
            ? (
              <div>
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/internet/pagos">
                  <p>Internet</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/internet/pagos">
                  <IconButton color="inherit">
                    <CashIcon />
                  </IconButton>
                  <p>Pagos</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/internet/clientes">
                  <IconButton color="inherit">
                    <ClientesIcon />
                  </IconButton>
                  <p>Clientes</p>
                </MenuItem>
                { HCUserLogged.data.rolUsuario === 'Admin'
                  && (
                    <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/internet/ganancias">
                      <IconButton color="inherit">
                        <GananciasIcon />
                      </IconButton>
                      <p>Ganancias</p>
                    </MenuItem>
                  )
                }
                <Divider light />
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/papeleria/monografias">
                  <p>Papeleria</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/papeleria/monografias">
                  <IconButton color="inherit">
                    <MonografiasIcon />
                  </IconButton>
                  <p>Monografías</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/papeleria/faltantes">
                  <IconButton color="inherit">
                    <FaltantesIcon />
                  </IconButton>
                  <p>Faltantes</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/papeleria/deudores">
                  <IconButton color="inherit">
                    <CashIcon />
                  </IconButton>
                  <p>Deudores</p>
                </MenuItem>
                <Divider light />
                <MenuItem onClick={this.handleMobileMenuClose}>
                  <IconButton color="inherit">
                    {/* <Badge badgeContent={11} color="secondary"> */}
                    <NotificationsIcon />
                    {/* </Badge> */}
                  </IconButton>
                  <p>Notificaciones</p>
                </MenuItem>
                <Divider light />
                <MenuItem onClick={this.handleProfileMenuOpen}>
                  <IconButton color="inherit">
                    <AccountCircle />
                  </IconButton>
                  <p>Perfil</p>
                </MenuItem>
              </div>
            )
            : (
              <MenuItem onClick={this.handleMobileMenuClose} component={Link} to="/login">
                <p>Login</p>
              </MenuItem>
            )
        }
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap component={Link} to="/">
              Papelería ARA
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              { HCUserLogged.isLogged
                ? (
                  <React.Fragment>
                    <Button component={Link} to="/internet/pagos" color="inherit">Internet</Button>
                    <Button component={Link} to="/papeleria" color="inherit">Papeleria</Button>
                    <IconButton color="inherit">
                      {/* <Badge badgeContent={7} color="secondary"> */}
                      <NotificationsIcon />
                      {/* </Badge> */}
                    </IconButton>
                    <IconButton
                      aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </React.Fragment>
                )
                : <Button component={Link} to="/login" color="inherit">Iniciar sesión</Button>
              }
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        {children}
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  clearLoggedUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(Layout);
