import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/common/Container';
import Formulario from '../../components/Internet/Clientes/FormCliente';
import {
  createCliente as createClienteAction,
  updateCliente as updateClienteAction,
} from '../../actions/cliente';
import {
  fetchClientes as fetchClientesAction,
} from '../../actions/clientes';

const FormClienteContainer = ({
  idCliente,
  createCliente,
  updateCliente,
  selectedCliente,
  fetchClientes,
  history,
  HCUserLogged,
}) => (
  <Container>
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        { idCliente !== '0'
          ? (
            <React.Fragment>
              <h2>Datos del cliente</h2>
              <Formulario
                onSubmit={updateCliente}
                initialValues={
                  Object.assign({}, selectedCliente.data, { HCUserLogged: HCUserLogged.data })
                }
                onSubmitSuccess={() => {
                  fetchClientes('ultimos-pagos', HCUserLogged.data);
                  history.goBack();
                }}
              />
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <h2>Datos del nuevo cliente</h2>
              <Formulario
                onSubmit={createCliente}
                initialValues={{
                  mensualidadCliente: 300,
                  statusCliente: 'Activo',
                  fk_idAntena: 1,
                  HCUserLogged: HCUserLogged.data,
                }}
                onSubmitSuccess={() => {
                  fetchClientes('ultimos-pagos', HCUserLogged.data);
                  history.goBack();
                }}
              />
            </React.Fragment>
          )
        }
      </Grid>
    </Grid>
  </Container>
);

FormClienteContainer.propTypes = {
  idCliente: PropTypes.string,
  createCliente: PropTypes.func.isRequired,
  updateCliente: PropTypes.func.isRequired,
  fetchClientes: PropTypes.func.isRequired,
  selectedCliente: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      telefonoCliente: PropTypes.string.isRequired,
      comunidadCliente: PropTypes.string.isRequired,
      direccionCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
      fechaRegistro: PropTypes.string.isRequired,
      fechaActualizacion: PropTypes.string.isRequired,
      fk_idAntena: PropTypes.number.isRequired,
    }),
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  history: PropTypes.shape().isRequired,
};

FormClienteContainer.defaultProps = {
  idCliente: '0',
};

const mapStateToProps = ({
  selectedCliente,
  HCUserLogged,
}) => ({
  selectedCliente,
  HCUserLogged,
});

const mapDispatchToProps = {
  createCliente: createClienteAction,
  updateCliente: updateClienteAction,
  fetchClientes: fetchClientesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormClienteContainer);
