import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '../../common/Container';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Grid } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Loading from '../../common/Loading';
import buscarMes from '../../../services/buscarMes';
import Estadisticas from './Estadisticas';

const BootstrapInput = withStyles(theme => ({
  root: {
    margin: '1rem 1rem 1rem 0',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const Meses = ({
  meses,
  ganancias,
  clearMes,
  fetchMeses,
  fetchGanancias,
  selectMes,
  idSelectedMes,
  HCUserLogged,
}) => {
  useEffect(() => {
    // clearMes();
    // clearGanancias();
    fetchMeses(HCUserLogged.data);
  }, []);

  const mesSeleccionado = idMes => {
    const nuevoMes = buscarMes(meses.data, idMes);
    selectMes(nuevoMes);
    fetchGanancias(HCUserLogged.data, nuevoMes.idMes);
  };

  return (
    <Container>
      { meses.isFetching
        ? <Loading />
        : ( meses.data.length > 0
          ? (
            <Grid container>
              <Grid item xs={12}>
                <FormControl className="w100">
                  <Select
                    value={idSelectedMes}
                    onChange={e => mesSeleccionado(e.target.value)}
                    input={<BootstrapInput name="tipoDocumento" id="age-customized-select" />}
                  >
                    <MenuItem disabled value={0} key={0}>Selecciona un mes</MenuItem>
                    {
                      meses.data.map(
                        mes => (
                          <MenuItem
                            value={mes.idMes}
                            key={mes.idMes}
                          >
                            {mes.nombreMes}
                          </MenuItem>
                        ),
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Container>
                { ganancias.isFetching
                  ? <Loading />
                  : ganancias.data
                    && <Estadisticas ganancias={ganancias}/>
                }
              </Container>
            </Grid>
          )
          : <h1>No se encontraron meses</h1>
        )
      }
    </Container>
  );
};

Meses.propTypes = {
  meses: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      idMes: PropTypes.number.isRequired,
      nombreMes: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  ganancias: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.shape({
      idMes: PropTypes.number.isRequired,
      nombreMes: PropTypes.string.isRequired,
      cantidadAPagar: PropTypes.string.isRequired,
      cantidadPago: PropTypes.string.isRequired,
      restante: PropTypes.string.isRequired,
    }),
  }).isRequired,
  idSelectedMes: PropTypes.number.isRequired,
  fetchMeses: PropTypes.func.isRequired,
  fetchGanancias: PropTypes.func.isRequired,
  clearMes: PropTypes.func.isRequired,
  selectMes: PropTypes.func.isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
};

export default Meses;
