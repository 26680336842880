import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MesCell from './MesCell';

const ClienteRow = ({
  cliente,
  selectCliente,
  selectPago,
  cliente: {
    nombreCliente,
    // statusCliente,
    pagos,
  },
}) => {
  const aditionalRows = [];
  for (let index = 0; index < (3 - pagos.length); index += 1) {
    aditionalRows.push(<TableCell key={index} />);
  }
  return (
    <TableRow onClick={() => selectCliente(cliente)}>
      <TableCell className="celda-link" style={{ padding: 0 }} component="td" scope="row">
        <Link
          className="decoration-none cursor-pointer"
          style={{
            display: 'block',
            padding: '.7rem',
            paddingLeft: '1rem',
            color: '#333',
          }}
          to="/internet/pagos/historial"
        >
          {nombreCliente}
        </Link>
      </TableCell>
      {aditionalRows}
      {pagos.map(pago => (
        <MesCell
          seleccionarPago={() => selectPago(pago)}
          key={pago.idPago}
          nombreMes={pago.mes.nombreMes}
          statusPago={pago.statusPago}
        />
      )).reverse()}
    </TableRow>
  );
};

ClienteRow.propTypes = {
  cliente: PropTypes.shape({
    idCliente: PropTypes.number.isRequired,
    folioCliente: PropTypes.string.isRequired,
    nombreCliente: PropTypes.string.isRequired,
    mensualidadCliente: PropTypes.number.isRequired,
    statusCliente: PropTypes.string.isRequired,
  }).isRequired,
  selectCliente: PropTypes.func.isRequired,
  selectPago: PropTypes.func.isRequired,
};

export default ClienteRow;
