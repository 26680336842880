import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const ClienteVisor = ({
  clearCliente,
  toggleCliente,
  HCUserLogged,
  selectedCliente: {
    idCliente,
    // folioCliente,
    nombreCliente,
    // telefonoCliente,
    comunidadCliente,
    direccionCliente,
    mensualidadCliente,
    statusCliente,
    fechaRegistro,
    // fechaActualizacion,
    // fk_idAntena,
  },
}) => (
  <Card>
    <CardHeader
      avatar={(
        <Avatar aria-label="Recipe">
          {nombreCliente.substr(0, 1)}
        </Avatar>
      )}
      action={(
        <div>
          <Hidden smDown>
            { (HCUserLogged.data && HCUserLogged.data.rolUsuario === 'Admin')
              && (
                <FormControlLabel
                  control={(
                    <Switch
                      checked={statusCliente === 'Activo'}
                      onChange={() => toggleCliente(idCliente, HCUserLogged.data)}
                      value="checkedB"
                      color="primary"
                    />
                  )}
                />
              )
            }
          </Hidden>
          <IconButton component={Link} to={`/internet/clientes/${idCliente}/editar`}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={clearCliente}>
            <CloseIcon />
          </IconButton>
          <Hidden mdUp>
            <Grid container justify="flex-end">
              { (HCUserLogged.data && HCUserLogged.data.rolUsuario === 'Admin')
                && (
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={statusCliente === 'Activo'}
                        onChange={() => toggleCliente(idCliente, HCUserLogged.data)}
                        value="checkedB"
                        color="primary"
                      />
                    )}
                  />
                )
              }
            </Grid>
          </Hidden>
        </div>
      )}
      title={nombreCliente}
      subheader={fechaRegistro}
    />
    <CardMedia
      image="/static/images/cards/paella.jpg"
      title="Paella dish"
    />
    <CardContent>
      <Typography component="p">{comunidadCliente}</Typography>
      <Typography color="textSecondary" gutterBottom>{direccionCliente}</Typography>
      <Typography variant="h5" component="h2">
        {`$ ${mensualidadCliente}.00`}
      </Typography>
      <Typography color="textSecondary">
        adjective
      </Typography>
      <Typography component="p">
        well meaning and kindly.
        <br />
        {'"a benevolent smile"'}
      </Typography>
    </CardContent>
  </Card>
);

ClienteVisor.propTypes = {
  clearCliente: PropTypes.func.isRequired,
  toggleCliente: PropTypes.func.isRequired,
  selectedCliente: PropTypes.shape({
    idCliente: PropTypes.number.isRequired,
    folioCliente: PropTypes.string.isRequired,
    nombreCliente: PropTypes.string.isRequired,
    telefonoCliente: PropTypes.string.isRequired,
    comunidadCliente: PropTypes.string.isRequired,
    direccionCliente: PropTypes.string.isRequired,
    mensualidadCliente: PropTypes.number.isRequired,
    statusCliente: PropTypes.string.isRequired,
    fechaRegistro: PropTypes.string.isRequired,
    fechaActualizacion: PropTypes.string.isRequired,
    fk_idAntena: PropTypes.number.isRequired,
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
};

export default ClienteVisor;
