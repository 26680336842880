import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const Loading = () => (
  <Grid align="center">
    <CircularProgress />
    <p>
      <span>Cargando</span>
    </p>
  </Grid>
);

export default Loading;
