import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';


import respuestasArrayCreator from '../../../services/respuestasArrayCreator';

const Monografias = ({
  documents,
  selectedDocumentType,
}) => {
  const arrayRespuestas = respuestasArrayCreator(documents);
  const papers = arrayRespuestas.map((respuestasByIndex) => {
    const indicePaper = respuestasByIndex[0].indice.letraIndice;
    return (
      <Grid key={indicePaper} item xs={12} sm={6} md={4}>
        <Paper elevation={5} className="p1">
          { (selectedDocumentType === 'Monografias' || selectedDocumentType === 'Biografias' || selectedDocumentType === 'Relieves')
            && (
              <Typography align="center" component="h2" variant="h4" gutterBottom>
                {indicePaper}
              </Typography>
            )
          }
          <List component="nav">
            {
              respuestasByIndex.map(({
                idDocumento,
                posicionDocumento,
                nombreDocumento,
              }) => (
                <ListItem key={idDocumento} button alignItems="flex-start">
                  <ListItemIcon>
                    <ListItemText primary={posicionDocumento} />
                  </ListItemIcon>
                  <ListItemText primary={nombreDocumento} />
                </ListItem>
              ))
            }
          </List>
        </Paper>
      </Grid>
    );
  });
  return (
    <Grid container spacing={16} alignItems="stretch">
      {papers}
    </Grid>
  );
};

Monografias.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      idDocumento: PropTypes.number.isRequired,
      nombreDocumento: PropTypes.string.isRequired,
      posicionDocumento: PropTypes.string.isRequired,
      indice: PropTypes.shape({
        idIndice: PropTypes.number.isRequired,
        letraIndice: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  selectedDocumentType: PropTypes.string.isRequired,
};

export default Monografias;
