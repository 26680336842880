import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const DatosCliente = ({
  selectedCliente: {
    data: {
      nombreCliente,
      comunidadCliente,
    },
  },
}) => (
  <>
    <Typography variant="h5" component="h3">
      {nombreCliente}
    </Typography>
    <Typography component="p" paragraph>
      {comunidadCliente}
    </Typography>
  </>
);

DatosCliente.propTypes = {
  selectedCliente: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      telefonoCliente: PropTypes.string.isRequired,
      comunidadCliente: PropTypes.string.isRequired,
      direccionCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
      fechaRegistro: PropTypes.string.isRequired,
      fechaActualizacion: PropTypes.string.isRequired,
      fk_idAntena: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default DatosCliente;
