import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles(theme => ({
  root: {
    margin: '1rem 1rem 1rem 0',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const PickerTipo = ({
  currentDocumentType,
  changeType,
  optionsTypes,
}) => (
  <FormControl>
    <Select
      value={currentDocumentType}
      onChange={e => changeType(e.target.value)}
      input={<BootstrapInput name="tipoDocumento" id="age-customized-select" />}
    >
      {
          optionsTypes.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)
      }
    </Select>
  </FormControl>
);

PickerTipo.propTypes = {
  currentDocumentType: PropTypes.string.isRequired,
  changeType: PropTypes.func.isRequired,
  optionsTypes: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
};

export default PickerTipo;
