import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import Layout from './components/common/Layout';
import Papeleria from './components/Papeleria';
import Faltantes from './components/Papeleria/Faltantes';
import Deudores from './components/Papeleria/Deudores';
import Perfil from './components/Perfil';
import AsyncMonografias from './containers/AsyncMonografias';
import Loading from './components/common/Loading';
import LoadError from './components/common/LoadError';
import Internet from './components/Internet';
import Pagos from './components/Internet/Pagos';
import NuevoPagoContainer from './containers/Internet/NuevoPagoContainer';
import NuevoMesContainer from './containers/Internet/NuevoMesContainer';
import Clientes from './components/Internet/Clientes';
import LoginContainer from './containers/LoginContainer';
import { fetchClientes as fetchClientesAction } from './actions/clientes';
import {
  selectCliente as selectClienteAction,
  clearCliente as clearClienteAction,
  fetchCliente as fetchClienteAction,
  toggleCliente as toggleClienteAction,
} from './actions/cliente';
import { selectPago as selectPagoAction } from './actions/pago';
import { toggleAlert as toggleAlertAction } from './actions/alert';
import { clearLoggedUser as clearLoggedUserAction } from './actions/loggedUser';
import GananciasContainer from './containers/Internet/GananciasContainer';
import FormClienteContainer from './containers/Internet/FormClienteContainer';
import PrivateRoute from './services/PrivateRoute';
import IsLogged from './services/IsLogged';
import HistorialContainer from './containers/Internet/HistorialContainer';
import FormPagoContainer from './containers/Internet/FormPagoContainer';
import MessageAlert from './components/common/MessageAlert';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
  },
  typography: { useNextVariants: true },
});

const App = ({
  selectPago,
  clientes,
  fetchClientes,
  selectedCliente,
  selectCliente,
  clearCliente,
  HCUserLogged,
  clearLoggedUser,
  alert,
  toggleAlert,
  toggleCliente,
}) => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <Layout HCUserLogged={HCUserLogged} clearLoggedUser={clearLoggedUser}>
        <LoadError>
          <Suspense fallback={<Loading />}>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/internet/pagos" />}
            />
            <Route
              path="/login"
              render={() => IsLogged(<LoginContainer />, HCUserLogged)
              }
            />
            <Route
              path="/internet"
              render={
                () => PrivateRoute(
                  <Internet
                    fetchClientes={fetchClientes}
                    HCUserLogged={HCUserLogged}
                  />,
                  HCUserLogged,
                )
              }
            />
            <Switch>
              <Route path="/internet/pagos/new" component={NuevoPagoContainer} />
              <Route path="/internet/pagos/mes" component={NuevoMesContainer} />
              <Route path="/internet/pagos/pagar" component={FormPagoContainer} />
              <Route path="/internet/pagos/historial" component={HistorialContainer} />
              <Route
                path="/internet/pagos"
                render={
                  () => (
                    <Pagos
                      clientes={clientes}
                      clearCliente={clearCliente}
                      selectCliente={selectCliente}
                      selectPago={selectPago}
                    />
                  )
                }
              />
            </Switch>
            <Switch>
              <Route path="/internet/clientes/:idCliente/editar" render={({ match, history }) => <FormClienteContainer idCliente={match.params.idCliente} history={history} />} />
              <Route path="/internet/clientes/new" component={FormClienteContainer} />
              <Route path="/internet/clientes" render={() => <Clientes HCUserLogged={HCUserLogged} toggleCliente={toggleCliente} clientes={clientes} selectedCliente={selectedCliente} clearCliente={clearCliente} selectCliente={selectCliente} />} />
            </Switch>
            <Route path="/internet/ganancias" component={GananciasContainer} />
            <Route
              exact
              path="/papeleria"
              render={() => <Redirect to="/papeleria/monografias" />
              }
            />
            <Route
              path="/papeleria"
              render={() => PrivateRoute(<Papeleria />, HCUserLogged)
              }
            />
            <Switch>
              <Route path="/papeleria/monografias" component={AsyncMonografias} />
              <Route path="/papeleria/deudores" component={Deudores} />
              <Route path="/papeleria/faltantes" component={Faltantes} />
            </Switch>
            <Route path="/perfil" component={Perfil} />
          </Suspense>
        </LoadError>
      </Layout>
    </Router>
    <MessageAlert
      isOpen={alert.isOpen}
      closeAlert={toggleAlert}
      message={alert.message}
      type={alert.type}
    />
  </MuiThemeProvider>
);

App.propTypes = {
  selectCliente: PropTypes.func.isRequired,
  clearCliente: PropTypes.func.isRequired,
  selectedCliente: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    }),
  }).isRequired,
  selectPago: PropTypes.func.isRequired,
  fetchClientes: PropTypes.func.isRequired,
  clientes: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  clearLoggedUser: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  toggleAlert: PropTypes.func.isRequired,
  toggleCliente: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  clientes,
  alert,
  selectedCliente,
  HCUserLogged,
}) => ({
  clientes,
  alert,
  selectedCliente,
  HCUserLogged,
});

const mapDispatchToProps = {
  fetchClientes: fetchClientesAction,
  fetchCliente: fetchClienteAction,
  selectCliente: selectClienteAction,
  selectPago: selectPagoAction,
  clearCliente: clearClienteAction,
  clearLoggedUser: clearLoggedUserAction,
  toggleAlert: toggleAlertAction,
  toggleCliente: toggleClienteAction,
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
