import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
// import { Tooltip } from '@material-ui/core';
// import formatFecha from '../../../../../services/formatFecha';

const MesCell = ({
  nombreMes,
  statusPago,
  seleccionarPago,
  // fechaPago,
}) => {
  let classMes = '';
  if (statusPago === 'Pagado') {
    classMes = 'mes-pagado';
  } else if (statusPago === 'Pendiente') {
    classMes = 'mes-pendiente';
  } else if (statusPago === 'Abonado') {
    classMes = 'mes-abonado';
  } else if (statusPago === 'No Aplica') {
    classMes = 'mes-no-aplica';
  }
  // const fechaDePago = formatFecha(fechaPago);
  return (
    statusPago === 'Pendiente' || statusPago === 'Abonado'
      ? (
        // <Tooltip title="Clic para pagar" aria-label="Clic para pagar" placement="top">
        <TableCell onClick={seleccionarPago} component="td" className={`${classMes} decoration-none`} align="center">
          <Link
            className="decoration-none cursor-pointer"
            style={{
              display: 'block',
              padding: '.7rem',
              color: '#333',
            }}
            to="/internet/pagos/pagar"
          >
            {nombreMes}
          </Link>
        </TableCell>
        // </Tooltip>
      )
      : <TableCell className={classMes} align="center">{nombreMes}</TableCell>
  );
};

MesCell.propTypes = {
  nombreMes: PropTypes.string.isRequired,
  statusPago: PropTypes.string.isRequired,
  seleccionarPago: PropTypes.func.isRequired,
  // fechaPago: PropTypes.string.isRequired,
};

export default MesCell;
