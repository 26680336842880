import React from 'react';
import PropTypes from 'prop-types';
import IconoPagado from '@material-ui/icons/CheckCircleOutline';
import IconoPendiente from '@material-ui/icons/CancelOutlined';
import IconoAbonado from '@material-ui/icons/ErrorOutline';
import IconoNoAplica from '@material-ui/icons/RemoveCircleOutline';
import './style.css';

const IconStatus = ({
  status,
}) => (
  <React.Fragment>
    {status === 'Pendiente' && <IconoPendiente className="icono-mes-pendiente" /> }
    {status === 'Pagado' && <IconoPagado className="icono-mes-pagado" />}
    {status === 'Abonado' && <IconoAbonado className="icono-mes-abonado" />}
    {status === 'No Aplica' && <IconoNoAplica className="icono-mes-no-aplica" />}
  </React.Fragment>
);

IconStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default IconStatus;
