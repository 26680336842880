import {
  REQUEST_PAGOS,
  RECEIVE_PAGOS,
  RECEIVE_PAGOS_FAIL,
  CLEAR_PAGOS,
} from '../actions/pagos';

const pagos = (state = {
  isFetching: false,
  messageError: null,
  data: null,
}, action) => {
  switch (action.type) {
    case RECEIVE_PAGOS:
      return Object.assign({}, state, {
        isFetching: false,
        messageError: null,
        data: action.pagos,
      });
    case CLEAR_PAGOS:
      return Object.assign({}, state, {
        messageError: null,
        data: null,
      });
    case REQUEST_PAGOS:
      return Object.assign({}, state, {
        isFetching: true,
        messageError: null,
      });
    case RECEIVE_PAGOS_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        messageError: action.messageError,
        data: null,
      });
    default:
      return state;
  }
};

export default pagos;
