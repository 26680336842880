import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { clearLoggedUser } from '../loggedUser/index';

export const REQUEST_CREATE_MES = 'REQUEST_CREATE_MES';
export const RECEIVE_CREATED_MES = 'RECEIVE_CREATED_MES';
export const RECEIVE_CREATED_MES_FAIL = 'RECEIVE_CREATED_MES_FAIL';

const requestCreateMes = () => ({
  type: REQUEST_CREATE_MES,
});

export const fetchCreateMes = mes => (dispatch) => {
  dispatch(requestCreateMes());
  return fetch(`${GLOBAL.url}meses`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        api_token: mes.HCUserLogged.api_token,
        idUsuario: mes.HCUserLogged.idUsuario,
      },
      body: JSON.stringify({
        nombreMes: `${mes.nombreMes} ${mes.anioMes}`,
      }),
    })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(showAlert('success', json.message));
      } else {
        dispatch(showAlert('warning', json.error));
        if (json.error === 'Autenticate primero') {
          dispatch(showAlert('warning', 'Tu sesión ha expirado'));
          dispatch(clearLoggedUser());
        }
      }
    })
    .catch(() => {
      dispatch(showAlert('error', 'Verifica tu conexión a internet'));
    });
};
