import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ClienteRow from '../ClienteRow';

const ClientesTable = ({
  clientes,
  cadFilter,
  selectCliente,
  selectPago,
}) => (
  <Paper style={{ overflowX: 'auto' }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nombre del Cliente</TableCell>
          <TableCell colSpan={3} align="center">Ultimos 3 meses</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { clientes.filter((cliente) => cliente.nombreCliente.toLowerCase().indexOf(cadFilter.toLowerCase()) > -1).length > 0
          ? ( clientes.filter((cliente) => cliente.nombreCliente.toLowerCase().indexOf(cadFilter.toLowerCase()) > -1).map(cliente => (
              <ClienteRow
                key={cliente.idCliente}
                cliente={cliente}
                selectCliente={selectCliente}
                selectPago={selectPago}
              />
            ))
          )
          : ( <TableRow>
                <TableCell align="center" colSpan={2}><h3>No se encontrarón clientes</h3></TableCell>
              </TableRow>
          )
        }
      </TableBody>
    </Table>
  </Paper>
);

ClientesTable.propTypes = {
  clientes: PropTypes.arrayOf(PropTypes.shape({
    idCliente: PropTypes.number.isRequired,
    folioCliente: PropTypes.string.isRequired,
    nombreCliente: PropTypes.string.isRequired,
    mensualidadCliente: PropTypes.number.isRequired,
    statusCliente: PropTypes.string.isRequired,
  })).isRequired,
  cadFilter: PropTypes.string.isRequired,
  selectCliente: PropTypes.func.isRequired,
  selectPago: PropTypes.func.isRequired,
};

export default ClientesTable;
