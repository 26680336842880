import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputField from '../../../common/Form/InputField';
import InputSelect from '../../../common/Form/InputSelect';
import Loading from '../../../common/Loading';

const validate = (values) => {
  const errors = {};
  if (!values.nombreCliente) {
    errors.nombreCliente = 'Introduce un nombre';
  } else if (values.nombreCliente.length < 6) {
    errors.nombreCliente = 'Debe tener mas de 5 caracteres';
  }
  if (!values.comunidadCliente) {
    errors.comunidadCliente = 'Este campo es obligatorio';
  }
  if (!values.direccionCliente) {
    errors.direccionCliente = 'Este campo es obligatorio';
  }
  if (!values.telefonoCliente) {
    errors.telefonoCliente = 'Este campo es obligatorio';
  // eslint-disable-next-line no-restricted-globals
  } else if (isNaN(Number(values.telefonoCliente))) {
    errors.telefonoCliente = 'Telefono no valido';
  } else if (values.telefonoCliente.length < 8) {
    errors.telefonoCliente = 'Telefono demaciado corto';
  }
  if (!values.mensualidadCliente) {
    errors.mensualidadCliente = 'Agrega una mensualida';
  // eslint-disable-next-line no-restricted-globals
  } else if (isNaN(Number(values.mensualidadCliente))) {
    errors.mensualidadCliente = 'Cantidad no valida';
  } else if (Number(values.mensualidadCliente) < 1) {
    errors.mensualidadCliente = 'Cantidad muy baja';
  }
  return errors;
};

const Formulario = ({
  handleSubmit,
  submitting,
  pristine,
  invalid,
}) => (
  <Grid>
    { submitting ? <Loading />
      : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Field
                name="nombreCliente"
                component={InputField}
                label="Nombre completo"
                placeholder="Ej. Julio Gómez"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comunidadCliente"
                component={InputField}
                label="Localidad"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="direccionCliente"
                component={InputField}
                label="Dirección"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="telefonoCliente"
                component={InputField}
                label="Teléfono"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="mensualidadCliente"
                component={InputField}
                label="Mensualiad"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="fk_idAntena"
                component={InputSelect}
                label="Antena"
              >
                <MenuItem value={1}>Pendiente</MenuItem>
                <MenuItem value={2}>Otra Antena</MenuItem>
              </Field>
            </Grid>
            <Grid item container justify="center">
              <Button type="submit" disabled={pristine || invalid} variant="contained" color="primary">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    }
  </Grid>
);

Formulario.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'clienteForm',
  validate,
})(Formulario);
