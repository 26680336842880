import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputSelect from '../../../common/Form/InputSelect';
import InputField from '../../../common/Form/InputField';
import Loading from '../../../common/Loading';

const validate = (values) => {
  const errors = {};
  if (!values.anioMes) {
    errors.anioMes = 'Debes agregar un año';
  // eslint-disable-next-line no-restricted-globals
  } else if (isNaN(Number(values.anioMes))) {
    errors.anioMes = 'Año no valido';
  } else if (Number(values.anioMes) < 2010 || Number(values.anioMes) > 2100) {
    errors.anioMes = 'Año no valido';
  }
  return errors;
};

const FormPago = ({
  handleSubmit,
  cancelSubmit,
  submitting,
}) => (
  <Grid>
    { submitting ? <Loading />
      : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={12} md={6}>
              <Field
                name="nombreMes"
                component={InputSelect}
                label="Mes"
              >
                <MenuItem value="Enero">Enero</MenuItem>
                <MenuItem value="Febrero">Febrero</MenuItem>
                <MenuItem value="Marzo">Marzo</MenuItem>
                <MenuItem value="Abril">Abril</MenuItem>
                <MenuItem value="Mayo">Mayo</MenuItem>
                <MenuItem value="Junio">Junio</MenuItem>
                <MenuItem value="Julio">Julio</MenuItem>
                <MenuItem value="Agosto">Agosto</MenuItem>
                <MenuItem value="Septiembre">Septiembre</MenuItem>
                <MenuItem value="Octubre">Octubre</MenuItem>
                <MenuItem value="Noviembre">Noviembre</MenuItem>
                <MenuItem value="Diciembre">Diciembre</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="anioMes"
                component={InputField}
                label="Año"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item container justify="space-around">
              <Button type="submit" variant="contained" color="primary">
                Agregar
              </Button>
              <Button variant="contained" className="m-left-1" onClick={cancelSubmit} color="secondary">
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    }
  </Grid>
);

FormPago.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'mesForm',
  validate,
})(FormPago);
