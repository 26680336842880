const formatFecha = (fecha) => {
  const dia = fecha.substring(8, 10);
  let mes = '';
  if (fecha.substring(5, 7) === '01') {
    mes = 'Enero';
  } else if (fecha.substring(5, 7) === '02') {
    mes = 'Febrero';
  } else if (fecha.substring(5, 7) === '03') {
    mes = 'Marzo';
  } else if (fecha.substring(5, 7) === '04') {
    mes = 'Abril';
  } else if (fecha.substring(5, 7) === '05') {
    mes = 'Mayo';
  } else if (fecha.substring(5, 7) === '06') {
    mes = 'Junio';
  } else if (fecha.substring(5, 7) === '07') {
    mes = 'Julio';
  } else if (fecha.substring(5, 7) === '08') {
    mes = 'Agosto';
  } else if (fecha.substring(5, 7) === '09') {
    mes = 'Septiembre';
  } else if (fecha.substring(5, 7) === '10') {
    mes = 'Octubre';
  } else if (fecha.substring(5, 7) === '11') {
    mes = 'Noviembre';
  } else if (fecha.substring(5, 7) === '12') {
    mes = 'Diciembre';
  }
  const anio = fecha.substring(0, 4);
  return `${dia}-${mes}-${anio}`;
};

export default formatFecha;
