import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputField from '../../../common/Form/InputField';
import Loading from '../../../common/Loading';

const validate = (values) => {
  const errors = {};
  if (!values.cantidadPago) {
    errors.cantidadPago = 'Debes agregar una cantidad';
  // eslint-disable-next-line no-restricted-globals
  } else if (isNaN(Number(values.cantidadPago))) {
    errors.cantidadPago = 'Cantidad no valida';
  } else if (Number(values.cantidadPago) < 1) {
    errors.cantidadPago = 'La cantidad no puede ser tan baja';
  }
  return errors;
};

const FormPago = ({
  handleSubmit,
  cancelSubmit,
  submitting,
}) => (
  <Grid>
    { submitting ? <Loading />
      : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Field
                name="cantidadPago"
                fontSize="3rem"
                component={InputField}
                label="Cantidad a pagar"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item container justify="space-around">
              <Button type="submit" variant="contained" color="primary">
                Pagar
              </Button>
              <Button variant="contained" className="m-left-1" onClick={cancelSubmit} color="secondary">
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    }
  </Grid>
);

FormPago.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'pagoForm',
  validate,
})(FormPago);
