import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import AddIconCircle from '@material-ui/icons/AddCircle';
import { green } from '@material-ui/core/colors';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Container from '../../../common/Container';
import FormPagoContainer from '../../../../containers/Internet/FormPagoContainer';
import buscarCliente from '../../../../services/buscarCliente';
import buscarPago from '../../../../services/buscarPago';
import Loading from '../../../common/Loading';
import Alerta from '../../../common/Alerta';
import FloatButton from '../../../common/FloatButton';

const BootstrapInput = withStyles(theme => ({
  root: {
    margin: '1rem 1rem 1rem 0',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const NuevoPago = ({
  history,
  clientes,
  selectCliente,
  selectPago,
  clearCliente,
  clearPago,
  idClienteSelected,
  idMesSelected,
  fetchPagosPendientes,
  pagosPendientes,
  clearPagosPendientes,
  HCUserLogged,
}) => {
  useEffect(() => {
    clearCliente();
    clearPago();
    clearPagosPendientes();
  }, []);

  const clienteSeleccionado = (idCliente) => {
    const nuevoCliente = buscarCliente(clientes.data, idCliente);
    fetchPagosPendientes(nuevoCliente.idCliente, HCUserLogged.data);
    selectCliente(nuevoCliente);
    clearPago();
  };
  const pagoSeleccionado = (idPago) => {
    clearPago();
    const nuevoPago = buscarPago(pagosPendientes.data, idPago);
    setTimeout(() => {
      selectPago(nuevoPago);
    }, 100);
  };

  return (
    <Container>
      <h2>Nuevo pago</h2>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormControl className="w100">
            <Select
              value={idClienteSelected}
              onChange={e => clienteSeleccionado(e.target.value)}
              input={<BootstrapInput name="tipoDocumento" id="age-customized-select" />}
            >
              <MenuItem disabled value={0} key={0}>Selecciona un cliente</MenuItem>
              {
                clientes.data.map(
                  cliente => (
                    <MenuItem
                      value={cliente.idCliente}
                      key={cliente.idCliente}
                    >
                      {cliente.nombreCliente}
                    </MenuItem>
                  ),
                )
              }
            </Select>
          </FormControl>
        </Grid>
        { pagosPendientes.data.length > 0
          && (
            <>
              <Grid item xs={12} md={6}>
                <FormControl className="w100">
                  <Select
                    value={idMesSelected}
                    onChange={e => pagoSeleccionado(e.target.value)}
                    input={<BootstrapInput name="tipoDocumento" id="age-customized-select" />}
                  >
                    <MenuItem disabled value={0} key={0}>Selecciona un mes</MenuItem>
                    { pagosPendientes.data
                      && pagosPendientes.data.map(
                        pago => (
                          <MenuItem
                            value={pago.idPago}
                            key={pago.idPago}
                          >
                            {pago.mes.nombreMes}
                          </MenuItem>
                        ),
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>
              <FormPagoContainer history={history} />
            </>
          )
        }
        <Grid item xs={12}>
          {(!!idClienteSelected && !pagosPendientes.isFetching && pagosPendientes.data.length === 0)
            && <Alerta message="El cliente no presenta adeudo" type="success" />
          }
          { !idClienteSelected
            && <Alerta message="Aun no has seleccionado un cliente" type="info" />
          }
          { pagosPendientes.isFetching
            && (
              <Loading />
            )
          }
        </Grid>
      </Grid>
      <FloatButton component={Link} to="/internet/pagos/mes" textButton="Agregar mes" variant="extended" icon={<AddIconCircle />} color={green[500]} />
    </Container>
  );
};

NuevoPago.propTypes = {
  history: PropTypes.shape().isRequired,
  clientes: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  pagosPendientes: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        idPago: PropTypes.number.isRequired,
        statusPago: PropTypes.string.isRequired,
        cantidadAPagar: PropTypes.number.isRequired,
        cantidadPago: PropTypes.number.isRequired,
        notasPago: PropTypes.string.isRequired,
        fechaPago: PropTypes.string.isRequired,
        mes: PropTypes.shape({
          idMes: PropTypes.number.isRequired,
          nombreMes: PropTypes.string.isRequired,
          fechaInicio: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  clearPagosPendientes: PropTypes.func.isRequired,
  selectCliente: PropTypes.func.isRequired,
  clearCliente: PropTypes.func.isRequired,
  selectPago: PropTypes.func.isRequired,
  clearPago: PropTypes.func.isRequired,
  idClienteSelected: PropTypes.number.isRequired,
  idMesSelected: PropTypes.number.isRequired,
  fetchPagosPendientes: PropTypes.func.isRequired,
};

export default NuevoPago;
