import { connect } from 'react-redux';
import { fetchPagos } from '../../actions/pagos';
import { selectPago, fetchResetPago, fetchDeshabilitarPago } from '../../actions/pago';
import PagosHistorial from '../../components/Internet/Pagos/PagosHistorial';

const mapStateToProps = ({
  selectedCliente,
  pagos,
  HCUserLogged,
}) => ({
  selectedCliente,
  pagos,
  HCUserLogged,
});

const mapDispatchToProps = {
  fetchPagos,
  fetchResetPago,
  fetchDeshabilitarPago,
  selectPago,
};

const HistorialContainer = connect(mapStateToProps, mapDispatchToProps)(PagosHistorial);

export default HistorialContainer;
