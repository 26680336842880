import React from 'react';
import { Redirect } from 'react-router-dom';

const PrivateRoute = (Component, HCUserLogged) => (
  HCUserLogged.isLogged
    ? Component
    : <Redirect to="/login" />
);

export default PrivateRoute;
