import fetch from 'isomorphic-fetch';
import GLOBAL from '../config';
import { showAlert } from '../alert';
import { clearLoggedUser } from '../loggedUser';

export const REQUEST_MESES = 'REQUEST_MESES';
export const RECEIVE_MESES = 'RECEIVE_MESES';
export const RECEIVE_MESES_FAIL = 'RECEIVE_MESES_FAIL';
export const SELECT_MES = 'SELECT_MES';
export const CLEAR_MES = 'CLEAR_MES';

export const selectMes = mes => ({
  type: SELECT_MES,
  mes,
});

export const clearMes = () => ({
  type: CLEAR_MES,
});

const requestMeses = () => ({
  type: REQUEST_MESES,
});

const receiveMeses = json => ({
  type: RECEIVE_MESES,
  meses: json.data,
});

const getMesesError = json => ({
  type: RECEIVE_MESES_FAIL,
  meses: json.error,
});

export const fetchMeses = (HCUserLogged) => (dispatch) => {
  dispatch(requestMeses());
  return fetch(
    `${GLOBAL.url}meses`,
    {
      headers: {
        'Content-Type': 'application/json',
        api_token: HCUserLogged.api_token,
        idUsuario: HCUserLogged.idUsuario,
      },
    },
  )
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'success') {
        dispatch(receiveMeses(json));
      } else {
        dispatch(getMesesError(json));
        if (json.error === 'Autenticate primero') {
          dispatch(clearLoggedUser());
          dispatch(showAlert('warning', 'Tu sesión expiro'));
        }
      }
    })
    .catch(() => {
      dispatch(getMesesError({ error: 'Verifica tu conexion a internet' }));
    });
};