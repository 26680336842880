import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconoObservaciones from '@material-ui/icons/ChatOutlined';
import Container from '../../../common/Container';
import Loading from '../../../common/Loading';
import IconStatus from '../../../common/IconStatus';
import formatFecha from '../../../../services/formatFecha';
import FormConfirmContainer from '../../../../containers/Internet/FormConfirmContainer';

const styles = {
  buttonDesabilitador: {
    marginTop: '.5rem',
  },
};

const PagosHistorial = ({
  selectedCliente,
  fetchPagos,
  fetchResetPago,
  fetchDeshabilitarPago,
  selectPago,
  pagos,
  HCUserLogged,
  classes,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openDeshabilitador, setOpenDeshabilitador] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpenDeshabilitador() {
    setOpenDeshabilitador(true);
  }

  function handleCloseDeshabilitador() {
    setOpenDeshabilitador(false);
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const eliminarPago = (pago) => {
    selectPago(pago);
    handleClickOpen();
  };

  const dehabilitarPago = (pago) => {
    selectPago(pago);
    handleClickOpenDeshabilitador();
  };

  useEffect(() => {
    if (selectedCliente.data) {
      fetchPagos(selectedCliente.data.idCliente, HCUserLogged.data);
    }
  }, []);

  return (
    <Container>
      {
        selectedCliente.data ? (
          <React.Fragment>
            <h2>{selectedCliente.data.nombreCliente}</h2>
            { pagos.isFetching && <Loading /> }
            { pagos.data && !pagos.isFetching
              && pagos.data.map(pago => (
                <ExpansionPanel key={pago.idPago} disabled={pago.statusPago === 'No Aplica'} expanded={expanded === pago.idPago} onChange={handleChange(pago.idPago)}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <IconStatus status={pago.statusPago} />
                    <Typography className="m-left-1">{pago.mes.nombreMes}</Typography>
                    { (pago.statusPago === 'Pagado' || pago.statusPago === 'Abonado')
                      && <Typography color="textSecondary" className="m-left-1">{formatFecha(pago.fechaPago)}</Typography>
                    }
                    { pago.notasPago !== 'Sin notas' && <IconoObservaciones className="m-left-1" fontSize="small" style={{ color: '#aaa' }} /> }
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="flex-dir-column">
                    { pago.statusPago === 'Abonado'
                      && (
                        <>
                          <Typography>Cantidad total</Typography>
                          <Typography variant="h5" paragraph>{`$${pago.cantidadAPagar}`}</Typography>
                        </>
                      )
                    }
                    { (pago.statusPago === 'Pagado' || pago.statusPago === 'Abonado')
                      && (
                        <>
                          <Typography>
                            { pago.statusPago === 'Pagado'
                              ? 'Pagado'
                              : 'Abonada'
                            }
                          </Typography>
                          <Typography variant="h5" paragraph>{`$${pago.cantidadPago}`}</Typography>
                        </>
                      )
                    }
                    { (pago.statusPago === 'Pendiente' || pago.statusPago === 'Abonado')
                      && (
                        <>
                          <Typography>Pendiente</Typography>
                          <Typography variant="h5" color="error" paragraph>{`$${pago.cantidadAPagar - pago.cantidadPago}`}</Typography>
                          { pago.notasPago !== 'Sin notas'
                            && <Typography paragraph color="textSecondary">{pago.notasPago}</Typography>
                          }
                          <Grid container justify="center">
                            <Grid item xs={12} md={4}>
                              <Fab
                                style={{ width: '100%' }}
                                component={Link}
                                to="/internet/pagos/pagar"
                                variant="extended"
                                color="primary"
                                onClick={() => selectPago(pago)}
                              >
                                Pagar mes
                              </Fab>
                            </Grid>
                          </Grid>
                          { (HCUserLogged.data && HCUserLogged.data.rolUsuario === 'Admin')
                            && (
                              <Grid container justify="center">
                                <Grid item xs={12} md={4}>
                                  <Fab className={classes.buttonDesabilitador} onClick={() => dehabilitarPago(pago)} style={{ width: '100%' }} variant="extended" aria-label="Reset pago">
                                    Deshabiliar pago
                                  </Fab>
                                </Grid>
                              </Grid>
                            )
                          }
                        </>
                      )
                    }
                    { (pago.statusPago === 'Pagado' || pago.statusPago === 'Abonado')
                      && (
                        <>
                          <Typography variant="h6" color="textSecondary">{pago.usuario.nombreCompleto}</Typography>
                          { pago.notasPago !== 'Sin notas'
                            && <Typography paragraph color="textSecondary">{pago.notasPago}</Typography>
                          }
                          { (HCUserLogged.data && HCUserLogged.data.rolUsuario === 'Admin')
                            && (
                              <Grid container justify="center">
                                <Grid item xs={12} md={4}>
                                  <Fab color="secondary" onClick={() => eliminarPago(pago)} style={{ width: '100%' }} variant="extended" aria-label="Reset pago">
                                    Eliminar pago
                                  </Fab>
                                </Grid>
                              </Grid>
                            )
                          }
                        </>
                      )
                    }
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
            }
          </React.Fragment>
        )
          : <h3>Selecciona un cliente</h3>
      }
      <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirmar borrado</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa tu contraseña para continuar.
            </DialogContentText>
            <FormConfirmContainer
              onSubmit={fetchResetPago}
              onSubmitSuccess={() => {
                fetchPagos(selectedCliente.data.idCliente, HCUserLogged.data);
                handleClose();
              }}
              cancelSubmit={handleClose}
            />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog open={openDeshabilitador} onClose={handleCloseDeshabilitador} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirmar desactivación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa tu contraseña para continuar.
            </DialogContentText>
            <FormConfirmContainer
              onSubmit={fetchDeshabilitarPago}
              onSubmitSuccess={() => {
                fetchPagos(selectedCliente.data.idCliente, HCUserLogged.data);
                handleCloseDeshabilitador();
              }}
              cancelSubmit={handleCloseDeshabilitador}
            />
          </DialogContent>
        </Dialog>
      </div>
    </Container>
  );
};

PagosHistorial.propTypes = {
  fetchResetPago: PropTypes.func.isRequired,
  fetchDeshabilitarPago: PropTypes.func.isRequired,
  selectedCliente: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    }),
  }).isRequired,
  fetchPagos: PropTypes.func.isRequired,
  selectPago: PropTypes.func.isRequired,
  pagos: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        idPago: PropTypes.number.isRequired,
        statusPago: PropTypes.string.isRequired,
        cantidadAPagar: PropTypes.number.isRequired,
        cantidadPago: PropTypes.number.isRequired,
        notasPago: PropTypes.string.isRequired,
        fechaPago: PropTypes.string.isRequired,
        mes: PropTypes.shape({
          idMes: PropTypes.number.isRequired,
          nombreMes: PropTypes.string.isRequired,
          fechaInicio: PropTypes.string.isRequired,
        }).isRequired,
        usuario: PropTypes.shape({
          idUsuario: PropTypes.number.isRequired,
          nombreUsuario: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(PagosHistorial);
