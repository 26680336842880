import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const InputField = ({
  input,
  label,
  fontSize,
  meta: { touched, error },
  ...custom
}) => (
  <React.Fragment>
    <TextField
      label={label}
      {...input}
      {...custom}
      inputProps={{
        style: { fontSize },
      }}
    />
    {touched
      && ((error && <span className="text-error">{error}</span>))}
  </React.Fragment>
);

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
};

InputField.defaultProps = {
  type: 'text',
  fontSize: '16',
};

export default InputField;
