import React from 'react';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const InputSelect = ({
  input,
  label,
  // meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl fullWidth>
    <InputLabel htmlFor="age-native-required">{label}</InputLabel>
    <Select
      // errorText={touched && error}
      {...input}
      onChange={value => input.onChange(value)}
      {...custom}
    >
      {children}
    </Select>
  </FormControl>
);

InputSelect.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
};

export default InputSelect;
