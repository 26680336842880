import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ClientesTable from './ClientesTable';
import Loading from '../../common/Loading';
import Container from '../../common/Container';
import FloatButton from '../../common/FloatButton';
import FiltrarClientes from '../../../containers/FiltrarClientes';

const Pagos = ({
  clientes,
  clearCliente,
  selectCliente,
  selectPago,
}) => {
  useEffect(() => {
    clearCliente();
  }, []);

  return (
    <Container>
      <h2>Pagos de internet</h2>
      <FiltrarClientes cadFilter={clientes.cadFilter} />
      { clientes.isFetching
        ? <Loading />
        : (
          <ClientesTable
            clientes={clientes.data}
            cadFilter={clientes.cadFilter}
            selectCliente={selectCliente}
            selectPago={selectPago}
          />
        )
      }
      <FloatButton component={Link} to="/internet/pagos/new" icon={<MoneyIcon />} color="#1976d2" />
    </Container>
  );
};

Pagos.propTypes = {
  clientes: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    cadFilter: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      idCliente: PropTypes.number.isRequired,
      folioCliente: PropTypes.string.isRequired,
      nombreCliente: PropTypes.string.isRequired,
      mensualidadCliente: PropTypes.number.isRequired,
      statusCliente: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  clearCliente: PropTypes.func.isRequired,
  selectCliente: PropTypes.func.isRequired,
  selectPago: PropTypes.func.isRequired,
};

export default Pagos;