import {
  REQUEST_DOCUMENTS,
  RECEIVE_DOCUMENTS,
  SELECT_TIPO_DOCUMENTO,
  RECEIVE_DOCUMENTS_FAIL,
} from '../actions/documentos';

export function selectedDocumentType(state = 'Monografias', action) {
  switch (action.type) {
    case SELECT_TIPO_DOCUMENTO:
      return action.tipoDocumento;
    default:
      return state;
  }
}

function documents(state = {
  isFetching: false,
  isError: false,
  messageError: '',
  items: [],
}, action) {
  switch (action.type) {
    case REQUEST_DOCUMENTS:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        messageError: '',
      });
    case RECEIVE_DOCUMENTS:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        messageError: '',
        items: action.documents,
      });
    case RECEIVE_DOCUMENTS_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        messageError: action.documents,
        items: [],
      });
    default:
      return state;
  }
}

export function documentsByType(state = { }, action) {
  switch (action.type) {
    case RECEIVE_DOCUMENTS:
    case REQUEST_DOCUMENTS:
    case RECEIVE_DOCUMENTS_FAIL:
      return Object.assign({}, state, {
        [action.tipoDocumento]: documents(state[action.tipoDocumento], action),
      });
    default:
      return state;
  }
}
