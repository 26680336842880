const buscarCliente = (clientes, idCliente) => {
  let nuevoCliente = null;
  clientes.forEach((cliente) => {
    if (cliente.idCliente === idCliente) {
      nuevoCliente = cliente;
    }
  });
  return nuevoCliente;
};

export default buscarCliente;
