import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import FormMes from '../../components/Internet/Pagos/FormMes';
import { fetchCreateMes as fetchCreateMesAction } from '../../actions/mes';
import Container from '../../components/common/Container';

const FormMesContainer = ({
  fetchCreateMes,
  history,
  HCUserLogged,
}) => {
  const fecha = new Date();
  const anio = fecha.getFullYear();
  const mes = fecha.getMonth();
  let nombreMes = 'Enero';
  switch (mes) {
    case 1:
      nombreMes = 'Febrero';
      break;
    case 2:
      nombreMes = 'Marzo';
      break;
    case 3:
      nombreMes = 'Abril';
      break;
    case 4:
      nombreMes = 'Mayo';
      break;
    case 5:
      nombreMes = 'Junio';
      break;
    case 6:
      nombreMes = 'Julio';
      break;
    case 7:
      nombreMes = 'Agosto';
      break;
    case 8:
      nombreMes = 'Septiembre';
      break;
    case 9:
      nombreMes = 'Octubre';
      break;
    case 10:
      nombreMes = 'Noviembre';
      break;
    case 11:
      nombreMes = 'Diciembre';
      break;
    default:
      nombreMes = 'Enero';
  }
  return (
    <Container>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <FormMes
            onSubmit={fetchCreateMes}
            initialValues={{
              nombreMes,
              anioMes: anio,
              HCUserLogged: HCUserLogged.data,
            }}
            onSubmitSuccess={() => {
              history.goBack();
            }}
            cancelSubmit={() => history.goBack()}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

FormMesContainer.propTypes = {
  fetchCreateMes: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  HCUserLogged: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    messageError: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = ({
  HCUserLogged,
}) => ({
  HCUserLogged,
});

const mapDispatchToProps = {
  fetchCreateMes: fetchCreateMesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormMesContainer);
